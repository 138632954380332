import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import Layout from "@layouts/index"
import SEO from "@components/seo"
import pageCss from "@css/components/common/page.module.styl"
import newsCss from "@css/components/news/news.module.styl"

import Footer from '@components/Footer'


class News extends React.PureComponent {

    makeList = () => {
        const { edges } = this.props.data.allNewsItemsJson
        let list = []
        for(let i = 0; i < edges.length; i++ ){
            let row = edges[i].node
            if( row.link !== "" ){
                list.push(
                    <li className={newsCss.item} key={i}>
                        <a className={newsCss.inner} href={row.link} target={row.target}>
                            <div className={newsCss.date}>{row.date}</div>
                            <div className={newsCss.title}>{row.title}</div>
                        </a>
                    </li>
                )
            } else {
                list.push(
                    <li className={newsCss.item} key={i}>
                        <div className={newsCss.inner}>
                            <div className={newsCss.date}>{row.date}</div>
                            <div className={newsCss.title}>{row.title}</div>
                        </div>
                    </li>
                )
            }
        }
        return (
            <ul className={newsCss.list}>
                {list}
            </ul>
        )
    }

    render(){
        const { location, data } = this.props
        const { bg01 } = data
        return(
            <div>
                <SEO title="NEWS" />
                <BackgroundImage className={pageCss.bg} fluid={bg01.childImageSharp.fluid}/>
                <main>
                    <div className={pageCss.container}>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>NEWS</p>
                            </div>
                            <div className={newsCss.container}>
                                {this.makeList()}
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

export default News

export const query = graphql`
    query {
        allNewsItemsJson {
            edges {
                node {
                    date
                    title
                    link
                    target
                }
            }
        }
        bg01 : file(relativePath: { eq: "global__img__sugar-bg-03@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 842) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

